import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/Devfest2024/Devfest2024/src/layout/mdx.tsx";
import { TeamMembers } from "../../components/team/teamMembers";
export const metadata = {
  title: "Team"
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SecondarySection = makeShortcode("SecondarySection");
const PrimarySection = makeShortcode("PrimarySection");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SecondarySection mdxType="SecondarySection">
      <h2>{`DevFest Nantes is supported by GDG Nantes.`}</h2>
      <p>{`The Google Developers Groups (GDGs) are groups of people interested in Google Platforms and Technologies: Android, Chrome, Google Apps, Angular, Cloud… These groups promoting sharing can be found all around the world.`}</p>
      <p>{`The objective of GDG Nantes is to organise events regularly:`}</p>
      <ul>
        <li parentName="ul">{`Google Technology Conferences and others on the Web, Cloud, Mobile, Big Data.`}</li>
        <li parentName="ul">{`Informal theme-based evening sessions around an Aperitif to facilitate meetings of similar minds and thoughts`}</li>
        <li parentName="ul">{`Codelabs to learn concretely and technically about technologies`}</li>
        <li parentName="ul">{`Competitions (Hackathon, …)`}</li>
        <li parentName="ul">{`Video retransmissions of major events: US conferences, keynotes`}</li>
      </ul>
      <p>{`To make all this possible, an awesome team of volunteers is working behind the scenes.`}</p>
    </SecondarySection>
    <PrimarySection mdxType="PrimarySection">
      <h2>{`Core Team`}</h2>
      <TeamMembers mdxType="TeamMembers" />
    </PrimarySection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      